import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Alert, Box, InputLabel, SelectChangeEvent, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ReusableButton from '../buttons/ReusableButton';
import ModalComponent from '../modal/ModalComponent';
import PTOForm from './PTOForm/PTOForm';
import TypeDropdownMenu from './TypeDropdownMenu/TypeDropdownMenu';
import DateAndTime from './DateAndTime/DateAndTime';
import ReasonRequested from './ReasonRequested/ReasonRequested';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_DATE_EVENT } from '../../graphql/dateEvents/mutations/createDateEvent';
import { useCurrentUser } from '../../../src/providers/CurrentUserContextProvider';
import Spinner from '../Spinner';
import { GET_EVENT_TYPE_BY_CODE } from '../../graphql/eventTypes/queries/getEventByCode';
import { v4 as uuidv4 } from 'uuid';
import { DateEvent } from '../../routes/User/Requests/EditRequest';
import { UPDATE_DATE_EVENT } from '../../graphql/dateEvents/mutations/updateDateEvent';
import UsersDropdown from '../Dropdowns/UsersDropdown';
import RequestStatusDropdown from '../Dropdowns/RequestStatusDropdown';
import { RequestStatus } from '../../enums/RequestStatus';
import { UPDATE_USER } from '../../graphql/users/mutations/updateUser';
import { GET_DATE_EVENT_BY_ID } from '../../graphql/dateEvents/queries/getDateEventById';

interface ReusableFormComponentProps {
  currentPage: string;
  existingEvent?: DateEvent;
  resetState?: () => void;
  disabled?: boolean;
}

const ReusableFormComponent: FC<ReusableFormComponentProps> = ({
  currentPage,
  existingEvent,
  resetState,
  disabled,
}) => {
  const parseDateTime = useCallback((dateType: 'start' | 'end', isoString?: string) => {
    const dateTime = isoString ? DateTime.fromISO(isoString) : DateTime.local();
    return {
      date: dateTime.toISODate()!,
      time: isoString ? dateTime.toFormat('HH:mm') : dateType === 'start' ? '09:00' : '17:00',
    };
  }, []);

  const start = parseDateTime('start', existingEvent?.start);
  const end = parseDateTime('end', existingEvent?.end);

  const navigate = useNavigate();
  const [eventType, setEventType] = useState<string>(currentPage);
  const [selectedCompensation, setSelectedCompensation] = useState<string>('paid');
  const [startDate, setStartDate] = useState<DateTime>(DateTime.fromISO(start.date));
  const [endDate, setEndDate] = useState<DateTime>(DateTime.fromISO(end.date));
  const [startTime, setStartTime] = useState<string>(start.time);
  const [endTime, setEndTime] = useState<string>(end.time);
  const [enteredText, setEnteredText] = useState<string>(existingEvent?.reasonRequested ?? '');
  const [isReasonEmpty, setIsReasonEmpty] = useState<boolean>(
    existingEvent ? !!existingEvent.reasonRequested?.length : true
  );
  const [isValidDateEntry, setIsValidDateEntry] = useState<boolean>(true);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    existingEvent?.status ?? RequestStatus.Pending
  );
  const [statusReason, setStatusReason] = useState<string>(existingEvent?.statusReason ?? '');

  const { data } = useQuery(GET_EVENT_TYPE_BY_CODE, {
    variables: {
      code: existingEvent ? existingEvent.eventType.code : currentPage,
    },
  });

  const generateUUID = () => {
    return uuidv4();
  };

  useEffect(() => {
    if (isSuccessful) {
      setShowSpinner(true);

      const timer = setTimeout(() => {
        setShowSpinner(false);
        setShowModal(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSuccessful]);

  const [createDateEvent] = useMutation(CREATE_DATE_EVENT);
  const [updateDateEvent] = useMutation(UPDATE_DATE_EVENT);
  const [updateUser] = useMutation(UPDATE_USER);
  const { currentUserData, isAdmin } = useCurrentUser();
  const { id } = useParams<{ id: string | undefined }>();
  const { data: dateEvent } = useQuery(GET_DATE_EVENT_BY_ID, {
    variables: {
      id: Number(id),
    },
  });

  const handleSelectUser = (userId: number) => {
    setSelectedUserId(userId);
  };

  const handleSelectStatus = (status: RequestStatus) => {
    setRequestStatus(status);
  };

  const handleChangeStatusReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusReason(event.target.value);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setEventType(e.target.value);
    if (!existingEvent) {
      navigate(`/requests/${e.target.value}`);
    }
  };

  const handleCompensationChange = (e: SelectChangeEvent<string>) =>
    setSelectedCompensation(e.target.value);

  const handleReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsReasonEmpty(true);
    setEnteredText(e.target.value);
  };

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) =>
    setStartDate(DateTime.fromISO(e.target.value));
  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>): void =>
    setEndDate(DateTime.fromISO(e.target.value));
  const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>): void =>
    setStartTime(e.target.value);
  const handleEndTimeChange = (e: ChangeEvent<HTMLInputElement>): void =>
    setEndTime(e.target.value);
  const handleReturnDashboard = () => navigate('/dashboard');
  const handleClose = () => {
    setIsSuccessful(false);
    resetState && resetState();
  };

  const isWeekend = (date: DateTime) => {
    const dayOfWeek = date.weekday;
    return dayOfWeek === 6 || dayOfWeek === 7;
  };

  const getDateRange = (startDate: DateTime, endDate: DateTime): DateTime[] => {
    const dates: DateTime[] = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      if (!isWeekend(currentDate)) {
        dates.push(currentDate);
      }
      currentDate = currentDate.plus({ days: 1 });
    }
    return dates;
  };

  const startOfDay = startDate.startOf('day');
  const endOfDay = endDate.startOf('day');
  const difference = endOfDay.diff(startOfDay, 'days').as('days');

  // this function - calculateHours - is called to return the number of hours in a given request. It is called during a successful form submission
  const calculateHours = (startTime: string, endTime: string): number => {
    const timeToHours = (timeStr: string): number => {
      const [hours, minutes] = timeStr.split(':').map(Number);
      return hours + minutes / 60;
    };
    const startHours = timeToHours(startTime);
    const endHours = timeToHours(endTime);
    return endHours - startHours;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let isFormValid = true;

    const startISO = startDate.toISO();
    const endISO = endDate.toISO();
    const startDateAmericanFormat = startDate.toFormat('MM/dd/yyyy');
    const endDateAmericanFormat = endDate.toFormat('MM/dd/yyyy');

    let startDateTime: DateTime | null = null;
    let endDateTime: DateTime | null = null;

    if (endISO && startISO) {
      startDateTime = DateTime.fromISO(startISO);
      endDateTime = DateTime.fromISO(endISO);

      if (endDateTime < startDateTime) {
        setIsValidDateEntry(false);
        isFormValid = false;
      } else if (startDateAmericanFormat === endDateAmericanFormat && endTime <= startTime) {
        setIsValidDateEntry(false);
        isFormValid = false;
      } else {
        setIsValidDateEntry(true);
      }
    } else {
      setIsValidDateEntry(true);
    }

    if (!enteredText) {
      setIsReasonEmpty(false);
      isFormValid = false;
    }

    if (isFormValid && startDateTime && endDateTime) {
      const validDates = getDateRange(startDateTime, endDateTime);
      let newGroupId: string | null = null;
      if (difference > 0) newGroupId = generateUUID();

      validDates.forEach((date, index) => {
        const isFirstDate = index === 0;
        const isLastDate = index === validDates.length - 1;
        const currentPTOHours = currentUserData?.userByEmail.ptoHours;
        const initialHours = dateEvent?.dateEventById.hours;
        const hours = calculateHours(startTime, endTime);

        if (existingEvent) {
          // within this block we are updating an existing event. To properly reflect accurate PTO hours for a user, we must first obtain what those hours are, then add back what was initially in the request before subtracting the hours in the new request.
          updateDateEvent({
            variables: {
              input: {
                id: existingEvent.id,
                eventType,
                reasonRequested: enteredText,
                start: `${date.toFormat('yyyy-MM-dd')} ${startTime}`,
                end: `${date.toFormat('yyyy-MM-dd')} ${endTime}`,
                userId: selectedUserId ?? existingEvent.user.id,
                status: requestStatus,
                hours: Math.min(hours, 8),
                statusReason,
                ...(eventType === 'pto' && { compensationType: selectedCompensation }),
              },
            },
          });

          if (eventType === 'pto') {
            // this updates the user's ptoHours accurately
            if (selectedCompensation === 'paid') {
              updateUser({
                variables: {
                  input: {
                    id: currentUserData?.userByEmail.id,
                    ptoHours:
                      requestStatus !== RequestStatus.Denied
                        ? currentPTOHours! + initialHours - Math.min(hours, 8)
                        : currentPTOHours! + initialHours,
                  },
                },
              });
            }
          } else if (eventType === 'wfh' || eventType === 'flex' || eventType === 'exception') {
            // within this block of code, if the eventType is wfh or flex, then based on additional conditionals -- different things can happen
            const wfhDays = currentUserData?.userByEmail.wfhDays ?? 0;
            const flexDays = currentUserData?.userByEmail.flexDays ?? 0;
            const exceptionDays = currentUserData?.userByEmail.exceptionDays ?? 0;

            const initialRequestStatus = dateEvent?.dateEventById.status;

            // if the initial request status of the request was denied, perform the logic in the if block
            if (initialRequestStatus === RequestStatus.Denied) {
              updateUser({
                variables: {
                  input: {
                    id: currentUserData?.userByEmail.id,
                    wfhDays:
                      eventType === 'wfh' && requestStatus !== RequestStatus.Denied
                        ? wfhDays - 1
                        : wfhDays,
                    flexDays:
                      eventType === 'flex' && requestStatus !== RequestStatus.Denied
                        ? flexDays - 1
                        : flexDays,
                    exceptionDays:
                      eventType === 'exception' && requestStatus !== RequestStatus.Denied
                        ? exceptionDays - 1
                        : exceptionDays,
                  },
                },
              });
            } else {
              updateUser({
                variables: {
                  input: {
                    id: currentUserData?.userByEmail.id,
                    wfhDays:
                      eventType === 'wfh' && requestStatus === RequestStatus.Denied
                        ? wfhDays + 1
                        : wfhDays,
                    flexDays:
                      eventType === 'flex' && requestStatus === RequestStatus.Denied
                        ? flexDays + 1
                        : flexDays,
                    exceptionDays:
                      eventType === 'exception' && requestStatus === RequestStatus.Denied
                        ? exceptionDays + 1
                        : exceptionDays,
                  },
                },
              });
            }
          }
        } else {
          createDateEvent({
            variables: {
              input: {
                userId: currentUserData?.userByEmail.id,
                eventTypeId: data?.eventType.id,
                reasonRequested: enteredText,
                start: `${date.toFormat('yyyy-MM-dd')} ${isFirstDate ? `${startTime}` : '09:00'}`,
                end: `${date.toFormat('yyyy-MM-dd')} ${isLastDate ? `${endTime}` : '17:00'}`,
                groupId: newGroupId,
                ...(eventType === 'pto' && { compensationType: selectedCompensation }),
              },
            },
          });

          // if eventType is 'pto' then do the logic in the if condition
          if (data?.eventType.code === 'pto') {
            if (selectedCompensation === 'paid') {
              const currentPTOHours = currentUserData?.userByEmail.ptoHours;
              // this calculates the number of hours in a given request
              const hours = calculateHours(startTime, endTime);
              // here we are updating a user to have their ptoHours be updated once a successful pto request is made. Hours should be properly subtracted with this
              updateUser({
                variables: {
                  input: {
                    id: currentUserData?.userByEmail.id,
                    ptoHours: currentPTOHours! - Math.min(hours, 8) * validDates.length,
                  },
                },
              });
            }
            // if eventType is 'flex' or 'wfh' then do the logic in the else if block
          } else if (
            data?.eventType.code === 'flex' ||
            data?.eventType.code === 'wfh' ||
            data?.eventType.code === 'exception'
          ) {
            const user = currentUserData?.userByEmail;

            if (user) {
              // initialize default values to avoid errors during calculations
              const currentWFH = user.wfhDays ?? null;
              const currentFlex = user.flexDays ?? null;
              const currentException = user.exceptionDays ?? null;

              // checking if the current values are valid before performing any operations
              const wfhDays =
                data?.eventType.code === 'wfh' && currentWFH != null
                  ? currentWFH - validDates.length
                  : currentWFH;
              const flexDays =
                data?.eventType.code === 'flex' && currentFlex != null
                  ? currentFlex - validDates.length
                  : currentFlex;
              const exceptionDays =
                data?.eventType.code === 'exception' && currentException != null
                  ? currentException - validDates.length
                  : currentException;

              //here we aer updating a user to have either their wfh or flex days updated once a successful wfh or flex request is made, respectively. Number should be properly subtracted with this
              updateUser({
                variables: {
                  input: {
                    id: currentUserData?.userByEmail.id,
                    wfhDays: wfhDays !== null ? wfhDays : user.wfhDays,
                    flexDays: flexDays !== null ? flexDays : user.flexDays,
                    exceptionDays: exceptionDays !== null ? exceptionDays : user.exceptionDays,
                  },
                },
              });
            }
          }
        }
      });
      setIsSuccessful(true);
    }
  };

  return (
    <>
      {showSpinner ? (
        <Spinner />
      ) : (
        <Box
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            margin: '0 auto',
            width: '85vw',
            maxWidth: 600,
          }}
          onSubmit={handleSubmit}
        >
          {eventType === 'pto' ? (
            <PTOForm
              selectedValue={eventType}
              selectedCompensation={selectedCompensation}
              handleSelectChange={handleSelectChange}
              handleCompensationChange={handleCompensationChange}
              disabled={disabled}
            />
          ) : (
            <TypeDropdownMenu
              selectedValue={eventType}
              handleSelectChange={handleSelectChange}
              disabled={disabled}
            />
          )}
          <DateAndTime
            startDate={startDate}
            startTime={startTime}
            handleStartDateChange={handleStartDateChange}
            handleStartTimeChange={handleStartTimeChange}
            endDate={endDate}
            endTime={endTime}
            handleEndDateChange={handleEndDateChange}
            handleEndTimeChange={handleEndTimeChange}
            disabled={disabled}
          />
          {isValidDateEntry ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Start date and time must be before end date and time.
            </Alert>
          )}
          <ReasonRequested
            enteredText={enteredText}
            handleReasonChange={handleReasonChange}
            disabled={disabled}
          />
          {isReasonEmpty ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Please fill out this field.
            </Alert>
          )}
          {existingEvent && isAdmin && (
            <>
              <InputLabel sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
                Owner
              </InputLabel>
              <UsersDropdown
                selectedUserId={existingEvent.user.id}
                handleSelectUser={handleSelectUser}
              />
              <InputLabel sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
                Status
              </InputLabel>
              <RequestStatusDropdown
                initialStatus={existingEvent.status}
                handleSelectStatus={handleSelectStatus}
              />
              <InputLabel sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
                Status reason
              </InputLabel>
              <TextField multiline value={statusReason} onChange={handleChangeStatusReason} />
            </>
          )}
          <ReusableButton
            style={{ width: '40%', alignSelf: 'left' }}
            type='submit'
            variant='outlined'
            disabled={disabled}
          >
            Submit
          </ReusableButton>
          {showModal ? (
            <ModalComponent
              open={isSuccessful}
              onClose={handleClose}
              onReturnDashboard={handleReturnDashboard}
            />
          ) : (
            ''
          )}
        </Box>
      )}
    </>
  );
};

export default ReusableFormComponent;
