import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { GET_ALL_USERS } from '../../graphql/users/queries/getAllUsers';
import { Link } from 'react-router-dom';
import { sortData, SortType } from '../../utils/sortData';
import { Role } from '../../enums/role';
import formatDate from '../../utils/FormatDate';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Spinner from '../../components/Spinner';

export interface GetAllUsers {
  id: number;
  username: string;
  email: string;
  role: Role;
  startDate: string;
  ptoRate: number;
  flexDays: number;
  wfhDays: number;
  emailSubscriptions: string;
  ptoHours: number;
  displayName: string;
  auth0Id: string | null;
}

export interface AdminPageColumnData {
  id: number;
  user: string;
  ptoHoursRemaining: number;
  flexDaysRemaining: number;
  wfhDaysRemaining: number;
  hireDate: string;
  daysUntilAnniversary: number;
}

interface ColumnData {
  dataKey: keyof AdminPageColumnData;
  label: string;
  numeric?: boolean;
}

const columns: ColumnData[] = [
  {
    label: 'User',
    dataKey: 'user',
  },
  {
    label: 'PTO Hours Remaining',
    dataKey: 'ptoHoursRemaining',
    numeric: true,
  },
  {
    label: 'Flex Days Remaining',
    dataKey: 'flexDaysRemaining',
    numeric: true,
  },
  {
    label: 'WFH Days Remaining',
    dataKey: 'wfhDaysRemaining',
    numeric: true,
  },
  {
    label: 'Hire Date',
    dataKey: 'hireDate',
  },
  {
    label: 'Days Until Anniversary',
    dataKey: 'daysUntilAnniversary',
  },
];

export const columnDataKeys = columns.map((column) => column.dataKey);

const createData = (
  id: number,
  user: string,
  ptoHoursRemaining: number,
  flexDaysRemaining: number,
  wfhDaysRemaining: number,
  hireDate: string,
  daysUntilAnniversary: number
): AdminPageColumnData => {
  return {
    id,
    user,
    ptoHoursRemaining,
    flexDaysRemaining,
    wfhDaysRemaining,
    hireDate,
    daysUntilAnniversary,
  };
};

const VirtuosoTableComponents: TableComponents<AdminPageColumnData> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

if (VirtuosoTableComponents.Scroller) {
  VirtuosoTableComponents.Scroller.displayName = 'Scroller';
}
if (VirtuosoTableComponents.TableHead) {
  VirtuosoTableComponents.TableHead.displayName = 'TableHead';
}
if (VirtuosoTableComponents.TableBody) {
  VirtuosoTableComponents.TableBody.displayName = 'TableBody';
}

const rowContent = (_index: number, row: AdminPageColumnData) => {
  return (
    <>
      {columns.map((column) => (
        <TableCell
          key={`${row.id}-${column.dataKey}`}
          sx={{
            width: { xs: '5rem', sm: '7rem' },
            fontSize: { xs: '0.85rem', sm: '1rem', md: '1.25rem', lg: '1.5rem' },
            padding: { xs: '0.5rem', sm: '0.75rem', md: '1rem' },
            backgroundColor: 'white',
          }}
          align='left'
        >
          {column.label === 'User' ? (
            <div
              style={{
                display: 'flex',
                fontWeight: 'bold',
              }}
            >
              <Link
                to={`/users/${row.id}/view`}
                style={{ textDecoration: 'none', color: '#43b458' }}
              >
                {row[column.dataKey]}
              </Link>
            </div>
          ) : (
            row[column.dataKey]
          )}
        </TableCell>
      ))}
    </>
  );
};

const AdminUserPage = () => {
  const [rows, setRows] = useState<AdminPageColumnData[] | null>(null);
  const [showSortingArrow, setShowSortingArrow] = useState<boolean>(true);
  const { data, loading, error } = useQuery(GET_ALL_USERS);
  const [sortDirection, setSortDirection] = useState<SortType>('desc');
  const [selectedSortIndex, setSelectedSortIndex] = useState<number>(0);

  useEffect(() => {
    if (data) {
      const updatedRows = data.users?.map((data: GetAllUsers) => {
        const givenDate = DateTime.fromISO(data.startDate);
        const today = DateTime.now().startOf('day');

        let nextAnniversary = givenDate.set({ year: today.year });
        if (nextAnniversary < today) {
          nextAnniversary = nextAnniversary.plus({ years: 1 });
        }

        const daysUntilAnniversary = Math.floor(nextAnniversary.diff(today, 'days').days);

        return createData(
          data.id,
          data.username,
          data.ptoHours,
          data.flexDays,
          data.wfhDays,
          data.startDate,
          daysUntilAnniversary
        );
      });
      setRows(updatedRows);
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  const handleDataSort = (column: ColumnData) => {
    setSortDirection((prevState) => {
      if (prevState === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
    if (rows) {
      const sortedRows = sortData(rows, column.dataKey, sortDirection);
      setRows(sortedRows);
    }
  };

  const displaySortIcon = (index: number) => {
    const iconStyles = {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '.1rem',
      fontSize: { xs: '1rem', md: '1.2rem' },
    };

    if (showSortingArrow && selectedSortIndex === index) {
      if (sortDirection === 'desc') {
        return <KeyboardArrowDownIcon sx={{ ...iconStyles }} />;
      } else {
        return <KeyboardArrowUpIcon sx={{ ...iconStyles }} />;
      }
    }
  };

  const tableHeader = () => {
    return (
      <TableRow>
        {columns?.map((column, index) => (
          <TableCell
            key={column.dataKey}
            variant='head'
            align={'left'}
            onMouseEnter={() => {
              setSelectedSortIndex(index);
              setShowSortingArrow(true);
            }}
            onMouseLeave={() => setShowSortingArrow(false)}
            onClick={() => handleDataSort(column)}
            sx={{
              gap: '2rem',
              lineHeight: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.75rem' },
              wordWrap: 'yes',
              width: { xs: '5rem', md: '7rem' },
              fontSize: { xs: '0.9rem', md: '1rem', lg: '1.3rem' },
              backgroundColor: 'white',
              borderBottom: '0.125rem solid #132229',
              position: 'relative',
            }}
          >
            {column.label}
            {displaySortIcon(index)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <Paper
      style={{
        height: '75vh',
        width: '75vw',
        marginTop: '2rem',
        borderWidth: '.1rem',
        boxShadow: '1rem 1rem 2rem #00000033',
      }}
    >
      {rows && (
        <TableVirtuoso
          data={rows.map((row) => {
            const { hireDate, ...rest } = row;
            return {
              ...rest,
              hireDate: formatDate(hireDate),
              key: row.id,
            };
          })}
          components={VirtuosoTableComponents}
          fixedHeaderContent={tableHeader}
          itemContent={rowContent}
        />
      )}
    </Paper>
  );
};

export default AdminUserPage;
