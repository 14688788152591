import { FC, useEffect } from 'react';
import { Box, Table, ClickAwayListener, Alert, useMediaQuery } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled, { useTheme } from 'styled-components';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import { useQuery } from '@apollo/client';
import { GET_ALL_PENDING_DATE_EVENTS } from '../../../graphql/dateEvents/queries/getAllPendingDateEvents';
import ReusableButton from '../../../components/buttons/ReusableButton';
import formatDate from '../../../utils/FormatDate';
import { capitalizeRequestType } from '../../../utils/capitalizeRequestType';
import { RequestStatus } from '../../../enums/RequestStatus';
import Header from '../../../components/text/Header';
import { useNavigate } from 'react-router-dom';

interface User {
  username: string;
}

interface EventType {
  code: string;
}

export interface Event {
  id: number;
  user: User;
  eventType: EventType;
  start: Date;
  hours: number;
  status?: RequestStatus;
}

interface StyledTableHeaderProps extends TableCellProps {
  textAlign?: 'left' | 'center' | 'right'; // Add other textAlign values if needed
}

export const StyledTableHeader = styled(TableCell)<StyledTableHeaderProps>(
  ({ theme, textAlign = 'left' }) => ({
    padding: theme.spacing(0.75),
    fontWeight: 700,
    lineHeight: '1.5rem',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    textAlign,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0.75),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
    },
  })
);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  fontSize: '1rem',
  lineHeight: '1.5rem',
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
}));

interface PendingEventsContextType {
  setDeny: (deny: boolean) => void;
}

const PendingEvents: FC<PendingEventsContextType> = () => {
  const { deny, setDeny, denyEvent, setDenyEvent } = useCurrentUser();
  const { data: dateEvent } = useQuery(GET_ALL_PENDING_DATE_EVENTS);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('400'));
  const navigate = useNavigate();

  useEffect(() => {
    if (deny) setDeny(true);
    if (denyEvent) setDenyEvent(true);
    return () => {
      setDeny(false);
      setDenyEvent(false);
    };
  }, [deny, denyEvent, setDeny, setDenyEvent]);

  const handleDenyClose = () => {
    setDeny(false);
    setDenyEvent(false);
  };

  return (
    <>
      {deny && (
        <ClickAwayListener onClickAway={handleDenyClose}>
          <Alert
            sx={{ justifyContent: 'center', width: '30%', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='warning'
          >
            Request denied.
          </Alert>
        </ClickAwayListener>
      )}

      {denyEvent && (
        <ClickAwayListener onClickAway={handleDenyClose}>
          <Alert
            sx={{ justifyContent: 'center', width: '30%', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='warning'
          >
            Request group denied.
          </Alert>
        </ClickAwayListener>
      )}
      <Box sx={{ display: 'flex', paddingTop: '1rem', marginX: '1rem' }}>
        {dateEvent?.dateEvents?.length ? (
          <TableContainer>
            <Header sx={{ marginTop: 0, marginBottom: '1.5rem' }}>Pending Events</Header>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableHeader sx={{ fontWeight: 'bold', width: '7rem' }}>
                    Owner
                  </StyledTableHeader>
                  <StyledTableHeader sx={{ fontWeight: 'bold', width: '3rem' }}>
                    Type
                  </StyledTableHeader>
                  <StyledTableHeader sx={{ fontWeight: 'bold' }}>Status</StyledTableHeader>
                  <StyledTableHeader sx={{ fontWeight: 'bold', width: '8rem' }}>
                    Date
                  </StyledTableHeader>
                  <StyledTableHeader sx={{ fontWeight: 'bold' }}>Hours</StyledTableHeader>
                  {!sm && <StyledTableHeader sx={{ fontWeight: 'bold' }}>Action</StyledTableHeader>}
                </TableRow>
              </TableHead>
              <TableBody>
                {dateEvent?.dateEvents?.map((event: Event, index: number) => (
                  <TableRow
                    key={`${event.start}-${index}`}
                    onClick={() => sm && navigate(`/requests/view/${event.id}`)}
                  >
                    <StyledTableCell>{event?.user?.username}</StyledTableCell>
                    <StyledTableCell>
                      {capitalizeRequestType(event?.eventType?.code)}
                    </StyledTableCell>
                    <StyledTableHeader
                      sx={{
                        position: 'relative',
                        textAlign: 'center',
                      }}
                    >
                      <PendingIcon
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '1.3rem',
                        }}
                      />
                    </StyledTableHeader>
                    <StyledTableCell>{formatDate(event.start)}</StyledTableCell>
                    <StyledTableCell>{event.hours}</StyledTableCell>
                    {!sm && (
                      <StyledTableCell>
                        <ReusableButton
                          size='small'
                          style={{ width: '5rem' }}
                          href={`/requests/view/${event.id}`}
                        >
                          View
                        </ReusableButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ width: '18.5rem' }}>
            <span style={{ textAlign: 'left' }}>There are no pending requests.</span>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PendingEvents;
