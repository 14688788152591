import { createTheme, ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    colors: ColorInterface;
  }

  interface ThemeOptions {
    colors: ColorInterface;
    breakpoints: BreakpointInterface;
  }
}

interface BreakpointInterface {
  values: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
}

const breakpoints: BreakpointInterface = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

interface ColorInterface {
  caxyGreen: string;
  caxyLightGray: string;
  caxyMediumGray: string;
  caxyNavy: string;
  gray: string;
  swiperNavigationSize: string;
  swipeThemeColor: string;
  white: string;
  black: string;
}

const colors: ColorInterface = {
  caxyGreen: '#43b458',

  caxyLightGray: '#efefef',

  caxyMediumGray: '#9ba7ad',

  caxyNavy: '#132229',

  gray: '#686868',

  swiperNavigationSize: '44px',

  swipeThemeColor: '#007aff',

  white: '#efefef',

  black: 'black',
};

const themeOptions: ThemeOptions = {
  colors,
  palette: {
    background: {
      default: colors.white,
    },
  },
  breakpoints,
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
    fontFamily: 'mabry-regular-pro',
    body1: {
      lineHeight: '1.3',
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
