import { ReactNode } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../Spinner';
import BaseErrorPage from '../../routes/ErrorPages/BaseErrorPage';
import { useQuery } from '@apollo/client';
import { GET_USER_BY_EMAIL } from '../../graphql/users/queries/getUserByEmail';

export interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isLoading: isAuthLoading, isAuthenticated, user: authUser } = useAuth0();

  const { data: currentUser, loading: isUserLoading } = useQuery(GET_USER_BY_EMAIL, {
    variables: {
      email: authUser?.email,
    },
  });

  if (isAuthLoading || isUserLoading) return <Spinner />;

  const loggedIn = authUser && currentUser && isAuthenticated;
  const loggedInNoUser = authUser && !currentUser && isAuthenticated;
  const notLoggedIn = !authUser && !currentUser && !isAuthenticated;

  const renderPage = () => {
    if (loggedIn) {
      return children;
    }
    if (notLoggedIn || loggedInNoUser) {
      return <BaseErrorPage error={403} message={"You're toad-ally not allowed here!"} />;
    }
    return <></>;
  };

  return <>{renderPage()}</>;
};

export default ProtectedRoute;
