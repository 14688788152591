import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import ReusableButton from '../buttons/ReusableButton';
import styled, { useTheme } from 'styled-components';
import formatDate from '../../utils/FormatDate';
import { useLocation, useNavigate } from 'react-router-dom';

interface TableData {
  status: string;
  start: string;
  hours: number;
  id: number;
  eventType: { code: string };
}

interface TabTableProps {
  data: TableData[];
  type: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const StyledTableCellLeftAlign = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.75),
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const TabTable: React.FC<TabTableProps> = ({ data = [], type }) => {
  const location = useLocation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('400'));
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', margin: '0 auto', paddingTop: '1rem', width: '100%' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 'bold', minWidth: '5rem' }}>
                Status
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold', minWidth: '7rem' }}>Date</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold', minWidth: '4rem' }}>Hours</StyledTableCell>
              {!sm && (
                <StyledTableCell sx={{ fontWeight: 'bold', minWidth: '4rem' }}>
                  Action
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) =>
              type === row?.eventType?.code ? (
                <TableRow
                  key={`${row.start}-${index}`}
                  onClick={() =>
                    sm &&
                    navigate(
                      location.pathname === '/event-history' && row.status === 'Pending'
                        ? `/requests/edit/${row.id}`
                        : `/requests/view/${row.id}`
                    )
                  }
                >
                  <StyledTableCellLeftAlign>{row.status}</StyledTableCellLeftAlign>
                  <StyledTableCellLeftAlign>{formatDate(row.start)}</StyledTableCellLeftAlign>
                  <StyledTableCell>{row.hours}</StyledTableCell>
                  {!sm && (
                    <StyledTableCell>
                      {location.pathname === '/event-history' && row.status === 'Pending' ? (
                        <ReusableButton
                          size='small'
                          style={{ width: '4rem' }}
                          href={`/requests/edit/${row.id}`}
                        >
                          View
                        </ReusableButton>
                      ) : (
                        <ReusableButton
                          size='small'
                          style={{ width: '4rem' }}
                          href={`/requests/view/${row.id}`}
                        >
                          View
                        </ReusableButton>
                      )}
                    </StyledTableCell>
                  )}
                </TableRow>
              ) : (
                ''
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TabTable;
