import { useQuery } from '@apollo/client';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import styled from 'styled-components';
import TabTable from '../../../components/Tables/TabTable';
import { GET_ALL_DATE_EVENTS_BY_ID } from '../../../graphql/dateEvents/queries/getDateEventsByUser';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import Header from '../../../components/text/Header';
import TimeUntilResets from '../../../components/userStats/TimeUntilResets';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTab = styled(Tab)({
  fontSize: '1rem',
  padding: '0.5rem',
  minWidth: 'auto',
});

const EventHistory: FC = () => {
  const { currentUserData } = useCurrentUser();
  const user = currentUserData?.userByEmail;
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const theme = useTheme();
  const { caxyGreen } = theme.colors;

  const { data: dateEvents } = useQuery(GET_ALL_DATE_EVENTS_BY_ID, {
    variables: {
      userId: user?.id,
    },
  });

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', marginX: '1rem' }}>
        <Header>Your Time Off Requests</Header>
        <TimeUntilResets variant='compact' />
        <Tabs
          value={activeTab}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: caxyGreen,
            },
            '& .Mui-selected': {
              color: 'black',
            },
            marginTop: '.5rem',
          }}
        >
          <StyledTab label='PTO' />
          <StyledTab label='Flex' />
          <StyledTab label='WFH' />
          <StyledTab label='Exception' />
        </Tabs>
        <CustomTabPanel value={activeTab} index={0}>
          <Box sx={{ height: '48vh', overflowY: 'auto' }}>
            <TabTable data={dateEvents?.dateEventByUserId} type={'pto'} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={1}>
          <Box sx={{ height: '48vh', overflowY: 'auto' }}>
            <TabTable data={dateEvents?.dateEventByUserId} type={'flex'} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={2}>
          <Box sx={{ height: '48vh', overflowY: 'auto' }}>
            <TabTable data={dateEvents?.dateEventByUserId} type={'wfh'} />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={3}>
          <Box sx={{ height: '48vh', overflowY: 'auto' }}>
            <TabTable data={dateEvents?.dateEventByUserId} type={'exception'} />
          </Box>
        </CustomTabPanel>
      </Box>
    </>
  );
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default EventHistory;
