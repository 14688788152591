import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, ClickAwayListener, Link, styled, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation, useQuery } from '@apollo/client';
import { GET_DATE_EVENT_BY_ID } from '../../graphql/dateEvents/queries/getDateEventById';
import { DateTime } from 'luxon';
import ReusableButton from '../../components/buttons/ReusableButton';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';
import BuildBreadcrumbs from '../../components/buildBreadcrumbs/buildBreadcrumbs';
import { UPDATE_USER } from '../../graphql/users/mutations/updateUser';
import { UPDATE_DATE_EVENT } from '../../graphql/dateEvents/mutations/updateDateEvent';
import { RequestStatus } from '../../enums/RequestStatus';
import { getDifferenceInHours } from '../../helpers/dateTime';
import toast from 'react-hot-toast';
import { REMOVE_DATE_EVENT } from '../../graphql/dateEvents/mutations/deleteDateEvent';
import Spinner from '../../components/Spinner';
import { BULK_APPROVE_OR_DENY_DATE_EVENT } from '../../graphql/dateEvents/mutations/bulkApproveOrDenyDateEvent';
import { Role } from '../../enums/role';
import { GET_USER_BY_EMAIL } from '../../graphql/users/queries/getUserByEmail';
import DeleteModalComponent from '../../components/modal/DeleteModalComponent';

const UserInfo = styled(Typography)`
  flex-direction: column;
  padding: 0.5rem;
  align-items: flex-start;
  text-align: left;
`;

interface User {
  username: string;
  id: number;
  ptoHours: number;
  wfhDays: number;
  flexDays: number;
  exceptionDays: number;
  role: Role;
}

type CompensationType = 'paid' | 'unpaid' | null;

interface EventType {
  name: string;
  code: string;
}

interface BulkApproveType {
  id: number;
  status: RequestStatus;
  start: string;
  end: string;
  user: User;
  eventType: EventType;
  compensationType: CompensationType;
}

interface UpdateDateEventType {
  end: string;
  start: string;
  eventType: EventType;
  id: number;
  reasonRequested: string;
  status: string;
  statusReason: string;
  user: User;
  compensationType: CompensationType;
}

const UserRequest: FC = () => {
  const [approved, setApproved] = useState<boolean>(false);
  const [approvedEvent, setApprovedEvent] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { id } = useParams<{ id: string | undefined }>();
  const idNum = Number(id);
  const { setDeny, setDenyEvent, currentUserData } = useCurrentUser();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_DATE_EVENT_BY_ID, {
    variables: {
      id: idNum,
    },
  });

  const updateUserRequestInfo = async (
    event: BulkApproveType | UpdateDateEventType,
    totalRequests: number
  ) => {
    const status = event.status;

    if (status === RequestStatus.Denied) {
      const { start, end, user, compensationType } = event;

      const { ptoHours, flexDays, wfhDays, exceptionDays, id: userId } = user;
      const code = event.eventType.code;
      const deniedHours = getDifferenceInHours(start, end) * totalRequests;
      const isUpdateUser =
        code === 'flex' ||
        code === 'wfh' ||
        code === 'exception' ||
        (code === 'pto' && compensationType === 'paid');

      if (isUpdateUser) {
        await updateUser({
          variables: {
            input: {
              id: userId,
              role: user.role,
              ptoHours: code === 'pto' ? ptoHours + deniedHours : ptoHours,
              flexDays: code === 'flex' ? flexDays + totalRequests : flexDays,
              wfhDays: code === 'wfh' ? wfhDays + totalRequests : wfhDays,
              exceptionDays: code === 'exception' ? exceptionDays + totalRequests : exceptionDays,
            },
          },
        });
      }

      if (totalRequests === 1) {
        setDeny(true);
      }
      if (totalRequests > 1) {
        setDenyEvent(true);
      }
    } else {
      if (totalRequests === 1) {
        setApproved(true);
      }
      if (totalRequests > 1) {
        setApprovedEvent(true);
      }
    }
  };

  const [bulkApproveOrDeny, { loading: bulkApproveDenyLoading }] = useMutation(
    BULK_APPROVE_OR_DENY_DATE_EVENT,
    {
      onCompleted: async (data) => {
        const { bulkApproveOrDeny } = data;
        // All bulk requests are for the same duration per day and same request type so just using the first index
        const event = bulkApproveOrDeny[0];
        const { compensationType, eventType } = event;
        const totalRequests = bulkApproveOrDeny.length;
        const isUnpaidPto = eventType.code === 'pto' && compensationType === 'unpaid';

        updateUserRequestInfo(event, totalRequests);
        if (isUnpaidPto) {
          navigate('/requests/pending');
        }
      },
      onError: (error) => {
        throw new Error('There was an error bulk approving events', error);
      },
    }
  );

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: () => [
      { query: GET_USER_BY_EMAIL, variables: { email: currentUserData?.userByEmail.email } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (currentUserData?.userByEmail.role === 'USER') {
        navigate('/requests');
      } else {
        navigate('/requests/pending');
      }
    },
    onError: (error) => {
      throw new Error('There was an error updating the user', error);
    },
  });

  const [updateDateEvent, { loading: updateDateEventLoading }] = useMutation(UPDATE_DATE_EVENT, {
    onCompleted: async (data) => {
      const { updateDateEvent } = data;
      const { compensationType, eventType } = updateDateEvent;

      const isUnpaidPto = eventType.code === 'pto' && compensationType === 'unpaid';
      updateUserRequestInfo(updateDateEvent, 1);
      if (isUnpaidPto) {
        navigate('/requests/pending');
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [removeDateEvent, { loading: removeDateEventLoading }] = useMutation(REMOVE_DATE_EVENT, {
    onCompleted: async (data) => {
      const { removeDateEvent } = data;
      const username = removeDateEvent.user?.username;
      const eventType = removeDateEvent.eventType?.name;
      const message = `${username}'s ${eventType} request was deleted!`;
      if (currentUserData?.userByEmail.role === 'USER') {
        navigate('/requests');
      } else {
        navigate('/requests/pending');
      }

      toast.error(message);
    },
    onError: (error) => {
      toast.error(`There was an error deleting this event. ${error.message}`);
    },
  });

  // need to use a useEffect hook here because being redirected when the error occurs is a side effect. Side effects should not be done directly inside the component body because it can lead to unintended behavior or performance issues.
  useEffect(() => {
    if (error) {
      navigate(`/404`);
    }
  }, [error, navigate]);
  if (loading) return <Spinner />;

  const formattedStartDateTime = DateTime.fromISO(data?.dateEventById?.start).toFormat(
    'MMM d, yyyy h:mm a'
  );
  const formattedEndtDateTime = DateTime.fromISO(data?.dateEventById?.end).toFormat(
    'MMM d, yyyy h:mm a'
  );

  const handleApproveClose = () => {
    setApproved(false);
    setApprovedEvent(false);
  };

  const crumbs = `/requests/view/${data?.dateEventById?.user?.username}`.split('/');

  const userData = [
    { label: 'User', value: data?.dateEventById.user.username },
    { label: 'Type', value: data?.dateEventById?.eventType?.name },
    { label: 'Status', value: data?.dateEventById?.status },
    { label: 'Reason', value: data?.dateEventById?.reasonRequested },
    { label: 'Start', value: formattedStartDateTime },
    { label: 'End', value: formattedEndtDateTime },
    { label: 'Owner', value: data?.dateEventById?.user?.displayName },
    { label: 'Remaining PTO', value: data?.dateEventById?.user?.ptoHours },
    { label: 'Remaining Flex', value: data?.dateEventById?.user?.flexDays },
    { label: 'Remaining WFH', value: data?.dateEventById?.user?.wfhDays },
    { label: 'Remaining Exception', value: data?.dateEventById?.user?.exceptionDays },
  ];

  const isBulkRequest = !!data?.dateEventById.groupId;

  const handleReturnBack = () => setShowModal(false);
  const handleClickDelete = () => {
    setShowModal(true);
  };

  // moving logic here for when the 'delete this request' link is clicked.
  const handleDeletingRequest = () => {
    // if eventType is pto, then do the logic in the 'if' block. here we are essentially adding the hours of a current request back to the available pto a user has
    // if eventType is wfh or flex, then do the logic in the 'else if' block. here we are adding 1 to the available wfh or flex a user has
    if (
      currentUserData?.userByEmail.role !== 'ADMIN' &&
      (data?.dateEventById.status === RequestStatus.Approved ||
        data?.dateEventById.status === RequestStatus.Denied)
    )
      return;

    const compensationType = data?.dateEventById.compensationType;

    if (
      data?.dateEventById.eventType?.code === 'pto' &&
      compensationType === 'paid' &&
      data?.dateEventById.status !== RequestStatus.Denied
    ) {
      updateUser({
        variables: {
          input: {
            id: currentUserData?.userByEmail.id,
            ptoHours: currentUserData?.userByEmail.ptoHours + data?.dateEventById.hours,
          },
        },
      });
    } else if (
      data?.dateEventById.eventType?.code === 'pto' &&
      compensationType === 'paid' &&
      data?.dateEventById.status === RequestStatus.Denied
    ) {
      updateUser({
        variables: {
          input: {
            id: currentUserData?.userByEmail.id,
            ptoHours: currentUserData?.userByEmail.ptoHours,
          },
        },
      });
    } else if (
      (data?.dateEventById.eventType?.code === 'wfh' ||
        data?.dateEventById.eventType?.code === 'flex' ||
        data?.dateEventById.eventType?.code === 'exception') &&
      data?.dateEventById.status !== RequestStatus.Denied
    ) {
      const wfhDays = currentUserData?.userByEmail.wfhDays ?? 0;
      const flexDays = currentUserData?.userByEmail.flexDays ?? 0;
      const exceptionDays = currentUserData?.userByEmail.exceptionDays ?? 0;

      const updatedFields = {
        id: currentUserData?.userByEmail.id,
        wfhDays: data?.dateEventById.eventType?.code === 'wfh' ? wfhDays + 1 : wfhDays,
        flexDays: data?.dateEventById.eventType?.code === 'flex' ? flexDays + 1 : flexDays,
        exceptionDays:
          data?.dateEventById.eventType?.code === 'exception' ? exceptionDays + 1 : exceptionDays,
      };
      updateUser({
        variables: {
          input: updatedFields,
        },
      });
    }
    removeDateEvent({
      variables: {
        id: idNum,
        userRole: currentUserData?.userByEmail.role,
      },
    });
  };

  return (
    <>
      <BuildBreadcrumbs path={crumbs} isBreadcrumbsRendered={true} />
      {data?.dateEventById.status === 'Approved' && currentUserData?.userByEmail.role === 'USER' ? (
        <Alert
          sx={{
            justifyContent: 'center',
            width: { xs: '17.5rem', sm: '20rem' },
            margin: '0 auto',
            marginTop: '1rem',
            marginBottom: '0.5rem',
          }}
          variant='filled'
          severity='success'
        >
          Request has been approved. Approved requests cannot be deleted. For further assistance,
          please contact Operations.
        </Alert>
      ) : (
        ''
      )}
      {data?.dateEventById.status === RequestStatus.Denied &&
      currentUserData?.userByEmail.role === 'USER' ? (
        <Alert
          sx={{
            justifyContent: 'center',
            width: { xs: '17.5rem', sm: '20rem' },
            margin: '0 auto',
            marginTop: '1rem',
            marginBottom: '0.5rem',
          }}
          variant='filled'
          severity='warning'
        >
          Request has been denied. Denied requests cannot be deleted. For further assistance, please
          contact Operations.
        </Alert>
      ) : (
        ''
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '19rem', sm: '25rem' },
        }}
      >
        {approved && (
          <ClickAwayListener onClickAway={handleApproveClose}>
            <Alert
              sx={{ justifyContent: 'center', width: '55%', margin: '0 auto', marginTop: '1rem' }}
              variant='filled'
              severity='success'
            >
              Request approved.
            </Alert>
          </ClickAwayListener>
        )}
        {approvedEvent && (
          <ClickAwayListener onClickAway={handleApproveClose}>
            <Alert
              sx={{ justifyContent: 'center', width: '55%', margin: '0 auto', marginTop: '1rem' }}
              variant='filled'
              severity='success'
            >
              Request group approved.
            </Alert>
          </ClickAwayListener>
        )}
        {data?.dateEventById.status !== 'Approved' &&
        data?.dateEventById.status !== RequestStatus.Denied ? (
          <Box sx={{ textAlign: 'right', marginTop: '0.5rem' }}>
            <ReusableButton style={{ width: '0.1rem', padding: 0 }}>
              <EditIcon onClick={() => navigate(`/requests/edit/${id}`)} />
            </ReusableButton>
          </Box>
        ) : (
          ''
        )}
        {userData.map((item, i) => (
          <UserInfo
            key={i}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              paddingLeft: 0,
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {item.label}: <span style={{ fontWeight: 'normal' }}>{item.value}</span>
            </span>
          </UserInfo>
        ))}
        {currentUserData?.userByEmail.role !== 'USER' ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.1rem',
                width: '100%',
                justifyContent: 'flex-start',
                marginTop: '1rem',
              }}
            >
              <ReusableButton
                style={{
                  height: '2rem',
                  width: '9rem',
                  backgroundColor: '#686868',
                  border: '3px solid #686868',
                  marginRight: '0.3rem',
                }}
                disabled={
                  data?.dateEventById.status === RequestStatus.Denied || updateDateEventLoading
                }
                onClick={() =>
                  updateDateEvent({
                    variables: {
                      input: {
                        id: idNum,
                        status: RequestStatus.Denied,
                        eventType: data?.dateEventById.eventType.code,
                      },
                    },
                  })
                }
              >
                Deny
              </ReusableButton>
              <ReusableButton
                style={{ height: '2rem', width: '9rem', marginBottom: '0.1rem' }}
                disabled={data?.dateEventById.status === 'Approved' || updateDateEventLoading}
                onClick={() =>
                  updateDateEvent({
                    variables: {
                      input: {
                        id: idNum,
                        status: RequestStatus.Approved,
                        eventType: data?.dateEventById.eventType.code,
                      },
                    },
                  })
                }
              >
                Approve
              </ReusableButton>
              {isBulkRequest && (
                <ReusableButton
                  style={{
                    height: '2rem',
                    width: '9rem',
                    lineHeight: '1.25rem',
                    backgroundColor: '#686868',
                    border: '#686868',
                    marginRight: '0.3rem',
                  }}
                  disabled={
                    data?.dateEventById.status === RequestStatus.Denied || bulkApproveDenyLoading
                  }
                  onClick={() =>
                    bulkApproveOrDeny({
                      variables: {
                        input: {
                          groupId: data?.dateEventById.groupId,
                          status: RequestStatus.Denied,
                        },
                      },
                    })
                  }
                >
                  Deny Group
                </ReusableButton>
              )}
              {isBulkRequest && (
                <ReusableButton
                  style={{ height: '2rem', width: '9rem', lineHeight: '1.25rem' }}
                  disabled={data?.dateEventById.status === 'Approved' || bulkApproveDenyLoading}
                  onClick={() =>
                    bulkApproveOrDeny({
                      variables: {
                        input: {
                          groupId: data?.dateEventById.groupId,
                          status: RequestStatus.Approved,
                        },
                      },
                    })
                  }
                >
                  Approve Group
                </ReusableButton>
              )}
            </Box>
            <Link
              disabled={removeDateEventLoading}
              component='button'
              variant='body2'
              sx={{
                textAlign: 'left',
                textDecoration: 'none',
                marginTop: '1rem',
                color: '#686868',
                fontSize: '1rem',
              }}
              onClick={handleClickDelete}
            >
              Delete this request
            </Link>
          </>
        ) : (
          <Link
            disabled={removeDateEventLoading}
            component='button'
            variant='body2'
            sx={{
              textAlign: 'left',
              textDecoration: 'none',
              marginTop: '0.2rem',
              color: '#686868',
              fontSize: '1rem',
            }}
            onClick={handleClickDelete}
          >
            Delete this request
          </Link>
        )}
        {showModal && (
          <DeleteModalComponent
            open={showModal}
            onClose={handleDeletingRequest}
            onReturnBack={handleReturnBack}
          />
        )}
      </Box>
    </>
  );
};

export default UserRequest;
