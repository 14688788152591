import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { FC } from 'react';
import ReusableButton from '../buttons/ReusableButton';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onReturnBack: () => void;
}

const DeleteModalComponent: FC<ModalProps> = ({ open, onClose, onReturnBack }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '23rem',
          bgcolor: '#efefef',
          borderRadius: 2,
          margin: 2,
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{ margin: '0 auto', paddingBottom: '1.5rem', width: '16rem' }}
      >
        <DialogContent sx={{ paddingLeft: 1 }}>
          <Typography sx={{ textAlign: 'left', fontSize: '1.15rem' }}>
            Are you sure you want to delete this request?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 0,
          }}
        >
          <ReusableButton onClick={onClose}>Yes</ReusableButton>
          <Box
            sx={{
              marginRight: 1,
            }}
          >
            <ReusableButton onClick={onReturnBack}>Hop Back</ReusableButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteModalComponent;
