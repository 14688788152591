import { Box, Breadcrumbs, Typography } from '@mui/material';
import { configureBreadcrumbString } from '../../utils/configureBreadcrumbString';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

interface BuildBreadcrumbsProps {
  path: string[];
  isBreadcrumbsRendered: boolean;
}

interface LinkTagProps extends LinkProps {
  underlineOnHover?: boolean;
}

export const LinkTag = styled(Link)<LinkTagProps>(({ underlineOnHover = true }) => ({
  textDecoration: 'none',
  color: 'black',
  width: '100%',
  height: '100%',
  '&:hover': {
    textDecoration: underlineOnHover ? 'underline' : 'none',
  },
}));

const crumbsToLink = [
  '',
  'home',
  'requests',
  'pending',
  'profile',
  'pto',
  'exception',
  'wfh',
  'flex',
  'dashboard',
  'users',
  'pto-spend',
  'total-benefits',
  'event-history',
];

const BuildBreadcrumbs: React.FC<BuildBreadcrumbsProps> = (data) => {
  // Creates an array of all possible breadcrumb options based on current url and then returns the selected option based on the index passed
  const getUrl = (index: number) => {
    const breadcrumbOptions = data.path?.map((_, index) => {
      let breadcrumbOption = '';

      for (let i = 0; i < index; i++) {
        breadcrumbOption += `/${data.path[i + 1]}`;
      }
      return breadcrumbOption;
    });
    return breadcrumbOptions[index];
  };

  return (
    <>
      {data.isBreadcrumbsRendered && (
        <div role='presentation'>
          <Box
            marginTop={'2rem'}
            borderRadius={'.18rem'}
            border={'1px solid black'}
            padding={'.5rem'}
            width={'18.8rem'}
            sx={{ backgroundColor: '#cfddd7' }}
          >
            <Breadcrumbs aria-label='breadcrumb'>
              {data.path?.map((crumb, i) =>
                crumbsToLink.includes(crumb) ? (
                  <LinkTag key={`${crumb}-${i}`} to={i === 0 ? '/' : getUrl(i)}>
                    {i === 0
                      ? `Home ${configureBreadcrumbString(crumb)}`
                      : configureBreadcrumbString(crumb)}
                  </LinkTag>
                ) : (
                  <Typography key={`${crumb}-${i}`} sx={{ color: 'black' }}>
                    {configureBreadcrumbString(crumb)}
                  </Typography>
                )
              )}
            </Breadcrumbs>
          </Box>
        </div>
      )}
    </>
  );
};

export default BuildBreadcrumbs;
