import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import ReusableButton from '../components/buttons/ReusableButton';
import { useCurrentUser } from '../providers/CurrentUserContextProvider';
import UserStats from '../components/userStats/UserStats';
import { Link } from 'react-router-dom';
import TimeUntilResets from '../components/userStats/TimeUntilResets';

//ommitting these imports until needed
//import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export const DashboardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
  height: auto;
  gap: 0.75rem;
  width: 90%;

  @media (min-width: 320px) {
    width: 17rem;
  }

  @media (min-width: 400px) {
    width: 23rem;
  }

  @media (min-width: 900px) {
    width: 27rem;
  }
`;

const Greeting = styled(Typography)`
  display: flex;
  width: 75%;
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 1.5rem;
`;

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  max-width: 40rem; /* Adjust based on your design */
  padding: 0 1rem; /* Optional padding */
`;

// PTOHour spreads a bit farther than dashboard buttons-needs fixing.
export const ResponsiveButton = styled(ReusableButton)(() => ({
  width: '90%',
}));

const Dashboard = () => {
  const { currentUserData } = useCurrentUser();
  const user = currentUserData?.userByEmail;

  // this useState hook is commented out as it is part of the PTO popup that we are commenting out for now
  // const [open, setOpen] = useState<boolean>(false);

  // commenting out the below useEffect hook that deals

  // useEffect(() => {
  //   // if the user has more than 2 days of PTO left, and less than 15 days before reset, trigger popup
  //   if (user?.ptoDays && user.ptoDays > 2 && daysUntilAnniversary < 15 && daysUntilAnniversay !== 0) {
  //     setOpen(true);
  //   }
  // }, [user?.ptoDays, daysUntilAnniversary]);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* Commenting out the feature below which prompts the user to take time off. Need to investigate how to only allow the prompt to appear once every 24 hours. */}

      {/*<Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center' }}>
            Your PTO resets in <strong>{daysUntilAnniversary}</strong>{' '}
            {daysUntilAnniversary === 1 ? 'day' : 'days'}!
            <br />
            Take some time off?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton variant='outlined' href='/requests/pto'>
            Request PTO
          </ReusableButton>
          <ReusableButton variant='outlined' onClick={handleClose}>
            Not Now
          </ReusableButton>
        </DialogActions>
  </Dialog>*/}

      <Greeting> Hello{user?.displayName ? `, ${user.displayName}` : ''}</Greeting>
      <Container>
        <UserStats />
      </Container>

      <TimeUntilResets />

      <Box sx={{ width: '75%', margin: '1rem auto 0rem auto' }}>
        <Link to='/requests' style={{ color: 'black', width: 'full' }}>
          <Typography>View my upcoming requests</Typography>
        </Link>
      </Box>

      <DashboardContainer>
        <ResponsiveButton variant='outlined' href='/requests/pto'>
          {' '}
          Request Time Off{' '}
        </ResponsiveButton>
        <ResponsiveButton variant='outlined' href='/requests/exception'>
          {' '}
          Request Exception Time{' '}
        </ResponsiveButton>
        <ResponsiveButton variant='outlined' href='/requests/wfh'>
          {' '}
          Request Work From Home{' '}
        </ResponsiveButton>
        <ResponsiveButton variant='outlined' href='/requests/flex'>
          {' '}
          Request Flex{' '}
        </ResponsiveButton>
        <ResponsiveButton variant='outlined' href='/event-history'>
          {' '}
          Event History{' '}
        </ResponsiveButton>
      </DashboardContainer>
    </Box>
  );
};

export default Dashboard;
