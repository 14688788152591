import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './routes/Layout';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { Auth0Provider } from '@auth0/auth0-react';
import './App.css';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/client';
import CurrentUserProvider from './providers/CurrentUserContextProvider';
import { isRouteAccessible, routes } from './routes';
import { Toaster } from 'react-hot-toast';
import { setContext } from '@apollo/client/link/context';

const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const tokenJSONString = localStorage.getItem(
    '@@auth0spajs@@::InOb4QCfL7GKd4yM1kvkEmwDfWjFafNA::@@user@@'
  );
  let token;

  if (tokenJSONString) {
    const tokenObj = JSON.parse(tokenJSONString);
    token = tokenObj.id_token;
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = from([authLink, httpLink]);

const client = new ApolloClient({
  link,
  cache,
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        cacheLocation='localstorage'
        useRefreshTokens={true}
        authorizationParams={{
          redirect_uri: `${process.env.REACT_APP_CLIENT_URL}/dashboard`,
        }}
      >
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CurrentUserProvider>
              <Layout>
                <Toaster position='top-center' />
                <Routes>
                  {routes.map((route, i) => (
                    <Route
                      key={`${route.path}-${i}`}
                      element={isRouteAccessible(route)}
                      path={route.path}
                    ></Route>
                  ))}
                </Routes>
              </Layout>
            </CurrentUserProvider>
          </BrowserRouter>
        </ThemeProvider>
      </Auth0Provider>
    </ApolloProvider>
  );
}

export default App;
