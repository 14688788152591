import { ReactNode } from 'react';
import BasicBreadcrumbs from './BasicBreadcrumbs';
import Box from '@mui/material/Box';
import NavigationBar from '../components/navigation/NavigationBar';

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Box sx={{ minHeight: '100vh' }}>
        <NavigationBar />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <BasicBreadcrumbs />
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
