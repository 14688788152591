import { useQuery } from '@apollo/client';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GET_DATE_EVENTS_FILTERED } from '../../../graphql/dateEvents/queries/getDateEventsFiltered';
import { useState } from 'react';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import { Event, StyledTableCell, StyledTableHeader } from '../Events/PendingEvents';
import formatDate from '../../../utils/FormatDate';
import { capitalizeRequestType } from '../../../utils/capitalizeRequestType';
import ReusableButton from '../../../components/buttons/ReusableButton';
import { Link } from 'react-router-dom';
import TimeUntilResets from '../../../components/userStats/TimeUntilResets';
import { DashboardContainer, ResponsiveButton } from '../../Dashboard';
import TimeManagementsBenefitsText from '../../../components/TimeManagementBenefitsText';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Header from '../../../components/text/Header';
import { Oval } from 'react-loader-spinner';

const RequestsPage = () => {
  const { currentUserData } = useCurrentUser();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const [detailsExtended, setDetailsExtended] = useState<boolean>(false);

  const { data, loading } = useQuery(GET_DATE_EVENTS_FILTERED, {
    variables: {
      input: {
        filters: {
          forUserId: currentUserData?.userByEmail.id,
        },
      },
    },
  });

  const events = data?.dateEventsFiltered ?? [];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: '1rem',
          marginX: '1rem',
        }}
      >
        <Header>Your Upcoming Requests</Header>
        <TimeUntilResets variant='compact' />
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <Oval
              visible={true}
              height='60'
              width='60'
              color={theme.colors.caxyGreen}
              ariaLabel='oval-loading'
            />
          </Box>
        ) : (
          <>
            <Table sx={{ marginY: '2rem' }}>
              <TableHead>
                <TableRow>
                  <StyledTableHeader>Type</StyledTableHeader>
                  <StyledTableHeader>Status</StyledTableHeader>
                  <StyledTableHeader>Date</StyledTableHeader>
                  <StyledTableHeader>Hours</StyledTableHeader>
                  <StyledTableHeader>Action</StyledTableHeader>
                </TableRow>
              </TableHead>
              {!!events.length && (
                <TableBody>
                  {events.map((event: Event, index: number) => (
                    <TableRow key={index}>
                      <StyledTableCell key={`${index}-code`}>
                        {capitalizeRequestType(event.eventType.code)}
                      </StyledTableCell>
                      <StyledTableCell key={`${index}-status`}>{event.status}</StyledTableCell>
                      <StyledTableCell key={`${index}-date`}>
                        {formatDate(event.start)}
                      </StyledTableCell>
                      <StyledTableCell key={`${index}-hours`}>{event.hours}</StyledTableCell>
                      <StyledTableCell>
                        <ReusableButton
                          size='small'
                          style={{ width: '5rem' }}
                          href={`/requests/view/${event.id}`}
                        >
                          View
                        </ReusableButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {!events.length && (
              <>
                <Typography sx={{ marginBottom: '0.25rem', textAlign: 'center' }}>
                  You have no upcoming requests. Would you like to{' '}
                  <Link to={'/requests/pto'} style={{ color: 'black' }}>
                    make a request?
                  </Link>
                </Typography>
              </>
            )}
          </>
        )}

        <DashboardContainer sx={{ paddingBottom: '4rem' }}>
          <ResponsiveButton variant='outlined' href='/requests/pto'>
            {' '}
            Request Time Off{' '}
          </ResponsiveButton>
          <ResponsiveButton variant='outlined' href='/requests/exception'>
            {' '}
            Request Exception Time{' '}
          </ResponsiveButton>
          <ResponsiveButton variant='outlined' href='/requests/wfh'>
            {' '}
            Request Work From Home{' '}
          </ResponsiveButton>
          <ResponsiveButton variant='outlined' href='/requests/flex'>
            {' '}
            Request Flex{' '}
          </ResponsiveButton>
          <ResponsiveButton variant='outlined' href='/event-history'>
            {' '}
            Event History{' '}
          </ResponsiveButton>
        </DashboardContainer>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: xs ? 54 : 64,
          right: detailsExtended ? 0 : '-31rem',
          maxHeight: `calc(100% - ${xs ? 54 : 64}px)`,
          maxWidth: '30rem',
          overflowY: 'scroll',
          borderLeft: '1px solid lightgray',
          paddingLeft: '1rem',
          backgroundColor: 'white',
          transition: 'right 0.5s ease-in-out',
        }}
      >
        <TimeManagementsBenefitsText />
      </Box>
      <IconButton
        onClick={() => setDetailsExtended((prev) => !prev)}
        sx={{
          position: 'fixed',
          top: '50%',
          right: detailsExtended ? '31.5rem' : '.5rem',
          transform: 'translateY(-50%)',
          transition: 'right 0.5s ease-in-out',
          zIndex: 10,
          backgroundColor: 'white',
          border: `1px solid lightgrey`,
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
          [theme.breakpoints.down('sm')]: {
            top: 'auto',
            bottom: 20,
            transform: 'translateY(0)',
            right: '1rem',
          },
        }}
      >
        {detailsExtended ? <ArrowForward /> : <ArrowBack />}
      </IconButton>
    </>
  );
};

export default RequestsPage;
