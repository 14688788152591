import { MouseEvent, useState } from 'react';
import { ClickAwayListener, Menu } from '@mui/material';
import { MenuItem } from '../navigation/MenuItem';

const RequestDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <MenuItem
          width={{ xs: '9rem', md: 'auto' }}
          padding={{ xs: '.38rem 1rem', md: 0 }}
          onClick={handleClick}
        >
          Request
        </MenuItem>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem onClick={handleClose} url='requests/pto' linkText='Request Time Off' />
          <MenuItem
            onClick={handleClose}
            url='/requests/exception'
            linkText='Request Exception Time'
          />
          <MenuItem onClick={handleClose} url='/requests/wfh' linkText='Request Work From Home' />
          <MenuItem onClick={handleClose} url='/requests/flex' linkText='Request Flex' />
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default RequestDropdown;
