import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import ReusableButton from '../../../components/buttons/ReusableButton';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import formatDate from '../../../utils/FormatDate';

const Container = styled(Box)`
  padding: 1rem;
`;
const ProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
`;

const ProfileData = styled(Typography)`
  display: flex;
  justify-content: left;
  font-size: 1rem;
  ${({ theme }) => `
    font-family: ${theme.typography.fontFamily};
  `}
`;

const Profile = () => {
  const { currentUserData } = useCurrentUser();
  const formattedDate = formatDate(currentUserData?.userByEmail?.startDate);

  return (
    <>
      {currentUserData && (
        <Container>
          <ProfileContainer>
            <ProfileData sx={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
              {' '}
              Email:{' '}
              <span style={{ fontWeight: 'normal', marginLeft: '0.5rem' }}>
                {currentUserData?.userByEmail?.email}
              </span>
            </ProfileData>
          </ProfileContainer>
          <ProfileContainer>
            <ProfileData sx={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
              Start Date:{' '}
              <span style={{ fontWeight: 'normal', marginLeft: '0.5rem' }}>{formattedDate}</span>
            </ProfileData>
          </ProfileContainer>
          <ProfileContainer>
            <ProfileData sx={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
              Display Name:{' '}
              <span style={{ fontWeight: 'normal', marginLeft: '0.5rem' }}>
                {currentUserData?.userByEmail?.displayName}
              </span>
            </ProfileData>
          </ProfileContainer>
        </Container>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}>
        <ReusableButton href='/profile/edit'>Edit Profile</ReusableButton>
        <ReusableButton>Connect Google</ReusableButton>
      </Box>
    </>
  );
};

export default Profile;
