import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../Spinner';
import { ProtectedRouteProps } from './ProtectedRoute';
import BaseErrorPage from '../../routes/ErrorPages/BaseErrorPage';
import { useQuery } from '@apollo/client';
import { GET_USER_BY_EMAIL } from '../../graphql/users/queries/getUserByEmail';
import { Role } from '../../enums/role';

const ProtectedAdminRoute = ({ children }: ProtectedRouteProps) => {
  const { isLoading: isAuthLoading, isAuthenticated, user: authUser } = useAuth0();

  const { data: currentUser, loading: isUserLoading } = useQuery(GET_USER_BY_EMAIL, {
    variables: {
      email: authUser?.email,
    },
  });

  if (isAuthLoading || isUserLoading) return <Spinner />;

  const userRole = currentUser?.userByEmail.role;
  const notLoggedIn = !authUser && !currentUser && !isAuthenticated;
  const loggedInNoUser = authUser && !currentUser && isAuthenticated;
  const loggedInNotAdmin = isAuthenticated && currentUser && userRole !== Role.ADMIN;
  const loggedInAdmin = isAuthenticated && currentUser && userRole === Role.ADMIN;

  const renderPage = () => {
    if (loggedInAdmin) {
      return children;
    }
    if (notLoggedIn || loggedInNoUser || loggedInNotAdmin) {
      return <BaseErrorPage error={403} message={"You're toad-ally not allowed here!"} />;
    }
    return <></>;
  };

  return <>{renderPage()}</>;
};

export default ProtectedAdminRoute;
