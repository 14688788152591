import { DateTime } from 'luxon';

export const formatISOString = (date: string) => {
  const luxonDate = DateTime.fromISO(date);

  const formattedDate = luxonDate.toFormat('MMM d, yyyy h:mm a');

  return formattedDate;
};

export const getDifferenceInHours = (start: string, end: string) => {
  const luxonStart = DateTime.fromISO(start);
  const luxonEnd = DateTime.fromISO(end);

  const differenceInHours = luxonEnd.diff(luxonStart, 'hours').hours;

  return differenceInHours;
};
