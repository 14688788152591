import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';
import ReusableButton from '../buttons/ReusableButton';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onReturnDashboard: () => void;
}

const Modal: FC<ModalProps> = ({ open, onClose, onReturnDashboard }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '23rem',
          bgcolor: '#efefef',
          borderRadius: 2,
          margin: 2,
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{ margin: '0 auto', paddingBottom: '1.5rem', width: '16rem' }}
      >
        <DialogTitle
          sx={{
            color: '#43b458',
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            paddingBottom: '0.313rem',
            paddingLeft: 1,
          }}
        >
          THANK YOU!
        </DialogTitle>
        <DialogContent sx={{ paddingLeft: 1 }}>
          <Typography sx={{ textAlign: 'left', fontSize: '1.15rem' }}>
            Your request has been successfully submitted.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 0,
          }}
        >
          <ReusableButton onClick={onClose} style={{ width: '15.5rem' }}>
            Submit Another Request
          </ReusableButton>
          <Box
            sx={{
              marginRight: 1,
            }}
          >
            <ReusableButton onClick={onReturnDashboard} style={{ width: '15.5rem' }}>
              Return to Dashboard
            </ReusableButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default Modal;
