import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
};

export const configureBreadcrumbString = (breadcrumb: string) => {
  const formattedCrumb = breadcrumb
    .replace(/-/g, ' ') // Replace hyphens with spaces
    .toLowerCase(); // Convert entire string to lowercase for consistent comparison

  // Define special cases
  const specialCases: { [key: string]: string } = {
    pto: 'New PTO',
    wfh: 'New WFH',
    exception: 'New Exception',
    flex: 'New Flex',
    'pto spend': 'PTO Spend',
  };

  // Check for special cases
  if (specialCases[formattedCrumb]) {
    return specialCases[formattedCrumb];
  }

  // For other cases, capitalize the first letter of each word
  return capitalizeWords(formattedCrumb);
};
