import { gql } from '@apollo/client';

export const GET_DATE_EVENT_BY_ID = gql`
  query getDateEventById($id: Int!) {
    dateEventById(id: $id) {
      compensationType
      end
      eventType {
        name
        code
      }
      id
      reasonRequested
      start
      status
      statusReason
      groupId
      status
      reasonRequested
      hours
      user {
        username
        id
        ptoHours
        wfhDays
        flexDays
        email
        exceptionDays
        displayName
      }
    }
  }
`;
