import { Box, Typography } from '@mui/material';
import frogIcon from '../../assets/images/frog-pixel-small.png';
import ReusableButton from '../../components/buttons/ReusableButton';

const ErrorPage = () => {
  return (
    <>
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100vw', sm: '600px' },
          marginTop: { sm: '5rem' },
        }}
      >
        <Box sx={{ width: { xs: '100%', sm: '50%' }, display: 'flex', justifyContent: 'center' }}>
          <img alt='frog icon' src={frogIcon}></img>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '50%' },
            textAlign: 'center',
            paddingLeft: { xs: 0, sm: '2rem' },
            paddingRight: { xs: 0, sm: '2rem' },
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: { xs: '8rem', sm: '9rem' } }}>404</Typography>
          <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
            Oops! Not clear how to leap to that page
          </Typography>
          <ReusableButton href='/dashboard'>Hop Back Home</ReusableButton>
        </Box>
      </Box>
    </>
  );
};

export default ErrorPage;
