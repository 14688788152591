import { useAuth0 } from '@auth0/auth0-react';
import ReusableButton from './buttons/ReusableButton';
import { ButtonProps } from '@mui/material';

const LoginLogout = ({
  isStylesDefault,
  customStyles,
}: {
  isStylesDefault?: boolean;
  customStyles?: ButtonProps;
}) => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const loginOrLogout = (isAuthenticated: boolean) => {
    return !isAuthenticated
      ? loginWithRedirect()
      : logout({ logoutParams: { returnTo: `${process.env.REACT_APP_CLIENT_URL}/login` } });
  };

  const getButtonTitle = (isAuthenticated: boolean) => {
    return !isAuthenticated ? 'Login' : 'Logout';
  };

  // Checks if isStyles is passed as true. If it is, the default button styles will be used.
  const styles = isStylesDefault
    ? undefined
    : customStyles
      ? customStyles.sx
      : {
          paddingTop: { xs: '0', md: '.375rem' },
          paddingBottom: { xs: '0', md: '.375rem' },
          paddingLeft: { xs: '0', md: '0.5rem' },
          paddingRight: { xs: '0', md: '0.5rem' },
          minWidth: { xs: '0', md: '4rem' },
          color: { xs: 'black', md: 'white' },

          '&:hover': {
            backgroundColor: { xs: 'transparent', md: 'inherit' },
            borderColor: { md: 'inherit' },
            color: { md: 'inherit' },
          },
        };

  return (
    <>
      <ReusableButton
        {...(styles && { sx: { ...styles } })}
        onClick={() => loginOrLogout(isAuthenticated)}
      >
        {getButtonTitle(isAuthenticated)}
      </ReusableButton>
    </>
  );
};

export default LoginLogout;
