import { InputLabel, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';

interface ReasonRequestedProps {
  enteredText: string;
  handleReasonChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const ReasonRequested: FC<ReasonRequestedProps> = ({
  enteredText,
  handleReasonChange,
  disabled,
}) => {
  return (
    <>
      <InputLabel sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
        Reason requested
      </InputLabel>
      <TextField
        value={enteredText}
        onChange={handleReasonChange}
        variant='outlined'
        inputProps={{ maxLength: 400 }}
        disabled={disabled}
      />
    </>
  );
};

export default ReasonRequested;
