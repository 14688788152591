import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { GET_ALL_USERS } from '../../graphql/users/queries/getAllUsers';
import { Link } from 'react-router-dom';
import { sortData, SortType } from '../../utils/sortData';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Spinner from '../../components/Spinner';
import { GetAllUsers } from '../Admin/AdminUserPage';

export interface TotalBenefitsColumnData {
  id: number;
  user: string;
  ptoHoursRemaining: number;
  flexDaysRemaining: number;
  wfhDaysRemaining: number;
}

interface ColumnData {
  dataKey: keyof TotalBenefitsColumnData;
  label: string;
  numeric?: boolean;
}

const columns: ColumnData[] = [
  {
    label: 'User',
    dataKey: 'user',
  },
  {
    label: 'PTO Hours Used',
    dataKey: 'ptoHoursRemaining',
    numeric: true,
  },
  {
    label: 'Flex Days Used',
    dataKey: 'flexDaysRemaining',
    numeric: true,
  },
  {
    label: 'WFH Days Used',
    dataKey: 'wfhDaysRemaining',
    numeric: true,
  },
];

export const columnDataKeys = columns.map((column) => column.dataKey);

const createData = (
  id: number,
  user: string,
  ptoHoursRemaining: number,
  flexDaysRemaining: number,
  wfhDaysRemaining: number
): TotalBenefitsColumnData => {
  return {
    id,
    user,
    ptoHoursRemaining,
    flexDaysRemaining,
    wfhDaysRemaining,
  };
};

const VirtuosoTableComponents: TableComponents<TotalBenefitsColumnData> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
  TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

if (VirtuosoTableComponents.Scroller) {
  VirtuosoTableComponents.Scroller.displayName = 'Scroller';
}
if (VirtuosoTableComponents.TableHead) {
  VirtuosoTableComponents.TableHead.displayName = 'TableHead';
}
if (VirtuosoTableComponents.TableBody) {
  VirtuosoTableComponents.TableBody.displayName = 'TableBody';
}

const rowContent = (_index: number, row: TotalBenefitsColumnData) => {
  return (
    <>
      {columns.map((column) => (
        <TableCell
          key={`${row.id}-${column.dataKey}`}
          sx={{
            width: { xs: '5rem', sm: '7rem' },
            fontSize: { xs: '0.85rem', sm: '1rem', md: '1.25rem', lg: '1.5rem' },
            padding: { xs: '0.5rem', sm: '0.75rem', md: '1rem' },
            backgroundColor: 'white',
          }}
          align='left'
        >
          {column.label === 'User' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                fontWeight: 'bold',
              }}
            >
              <Link
                to={`/users/${row.id}/view`}
                style={{ textDecoration: 'none', color: '#43b458' }}
              >
                {row[column.dataKey]}
              </Link>
            </div>
          ) : (
            row[column.dataKey]
          )}
        </TableCell>
      ))}
    </>
  );
};

const TotalBenefitsPage = () => {
  const [rows, setRows] = useState<TotalBenefitsColumnData[] | null>(null);
  const [showSortingArrow, setShowSortingArrow] = useState<boolean>(true);
  const { data, loading, error } = useQuery(GET_ALL_USERS);
  const [sortDirection, setSortDirection] = useState<SortType>('desc');
  const [selectedSortIndex, setSelectedSortIndex] = useState<number>(0);

  useEffect(() => {
    if (data) {
      const updatedRows = data.users?.map((data: GetAllUsers) => {
        const givenDate = DateTime.fromISO(data.startDate);
        const today = DateTime.now().startOf('day');

        let nextAnniversary = givenDate.set({ year: today.year });
        if (nextAnniversary < today) {
          nextAnniversary = nextAnniversary.plus({ years: 1 });
        }

        const yearsSinceStart = today.diff(givenDate, 'years').years;

        let ptoLimit = 0;

        // First year logic - increase by 10 each month up to 120 in total
        if (yearsSinceStart < 1) {
          // Give the user 10 hours at the start
          let totalHours = 10;

          // If the start date is before or on the 1st of the current month, calculate additional hours
          if (givenDate < today) {
            // Start counting from the first of the month after the start date
            let firstOfNextMonth = givenDate.plus({ months: 1 }).startOf('month');

            // Loop through each 1st of the month that has passed and add 10 hours
            while (firstOfNextMonth <= today.startOf('month')) {
              totalHours += 10;
              firstOfNextMonth = firstOfNextMonth.plus({ months: 1 }); // Move to the 1st of the next month
            }
          }
          ptoLimit = Math.min(120, totalHours);
        } else if (yearsSinceStart >= 1 && yearsSinceStart < 3) {
          // Between year 1 and year 3, default to 120
          ptoLimit = 120;
        } else if (yearsSinceStart >= 3) {
          // After 3 years, use 160
          ptoLimit = 160;
        }

        return createData(
          data.id,
          data.username,
          ptoLimit - data.ptoHours,
          24 - data.flexDays,
          24 - data.wfhDays
        );
      });
      setRows(updatedRows);
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  const handleDataSort = (column: ColumnData) => {
    setSortDirection((prevState) => {
      if (prevState === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
    if (rows) {
      const sortedRows = sortData(rows, column.dataKey, sortDirection);
      setRows(sortedRows);
    }
  };

  const displaySortIcon = (index: number) => {
    const iconStyles = {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '.1rem',
      fontSize: { xs: '1rem', md: '1.2rem' },
    };

    if (showSortingArrow && selectedSortIndex === index) {
      if (sortDirection === 'desc') {
        return <KeyboardArrowDownIcon sx={{ ...iconStyles }} />;
      } else {
        return <KeyboardArrowUpIcon sx={{ ...iconStyles }} />;
      }
    }
  };

  const tableHeader = () => {
    return (
      <TableRow>
        {columns?.map((column, index) => (
          <TableCell
            key={column.dataKey}
            variant='head'
            align={'left'}
            onMouseEnter={() => {
              setSelectedSortIndex(index);
              setShowSortingArrow(true);
            }}
            onMouseLeave={() => setShowSortingArrow(false)}
            onClick={() => handleDataSort(column)}
            sx={{
              gap: '2rem',
              lineHeight: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.75rem' },
              wordWrap: 'yes',
              width: { xs: '5rem', md: '7rem' },
              fontSize: { xs: '0.9rem', md: '1rem', lg: '1.3rem' },
              backgroundColor: 'white',
              borderBottom: '0.125rem solid #132229',
              position: 'relative',
            }}
          >
            {column.label}
            {displaySortIcon(index)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <>
      <Typography style={{ textAlign: 'center', marginTop: '2rem', fontSize: '1.5rem' }}>
        Total Benefits Used
      </Typography>
      <Paper
        style={{
          height: '75vh',
          width: '75vw',
          marginTop: '2rem',
          borderWidth: '.1rem',
          boxShadow: '1rem 1rem 2rem #00000033',
        }}
      >
        {rows && (
          <TableVirtuoso
            data={rows}
            components={VirtuosoTableComponents}
            fixedHeaderContent={tableHeader}
            itemContent={rowContent}
          />
        )}
      </Paper>
    </>
  );
};

export default TotalBenefitsPage;
