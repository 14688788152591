import { FC } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import TypeDropdownMenu from '../TypeDropdownMenu/TypeDropdownMenu';

interface PTOFormProps {
  selectedCompensation: string;
  selectedValue: string;
  handleSelectChange: (e: SelectChangeEvent<string>) => void;
  handleCompensationChange: (e: SelectChangeEvent<string>) => void;
  disabled?: boolean;
}
const PTOForm: FC<PTOFormProps> = ({
  selectedCompensation,
  selectedValue,
  handleSelectChange,
  handleCompensationChange,
  disabled,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: -2 } }}>
          <TypeDropdownMenu
            selectedValue={selectedValue}
            handleSelectChange={handleSelectChange}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel sx={{ fontWeight: 'bold', color: 'black' }}>Compensation Type</InputLabel>
          <FormControl fullWidth>
            <Select
              value={selectedCompensation}
              onChange={handleCompensationChange}
              disabled={disabled}
            >
              <MenuItem value='paid'>Paid</MenuItem>
              <MenuItem value='unpaid'>Unpaid</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PTOForm;
