import { MouseEvent, useState } from 'react';
import { Button, ClickAwayListener, Menu, MenuItem, Typography } from '@mui/material';

interface Props {
  paddingX?: string;
}

const ReportsDropdown = ({ paddingX }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button
          onClick={handleClick}
          variant='text'
          color='inherit'
          sx={{ paddingX, minHeight: '2.5rem', marginLeft: '-0.5rem' }}
        >
          <Typography sx={{ textTransform: 'none' }}>Reports</Typography>
        </Button>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem href={'/reports/pto-spend'} component='a'>
            PTO Spend
          </MenuItem>
          <MenuItem href={'/reports/total-benefits'} component='a'>
            Total Benefits Used
          </MenuItem>
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default ReportsDropdown;
