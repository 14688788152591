import { Box, useTheme, Tab, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react';
import UserTable from '../../components/Tables/UserTable';
import { useQuery } from '@apollo/client';
import { GET_PTO_SPEND } from '../../graphql/reports/queries/getPtoSpend';
import Spinner from '../../components/Spinner';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/text/Header';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTab = styled(Tab)({
  fontSize: '1rem',
  padding: '.25rem',
});

const PtoSpend: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { caxyGreen } = theme.colors;
  const { data, loading, error } = useQuery(GET_PTO_SPEND);

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (error) {
      navigate(`/404`);
    }
  }, [error, navigate]);

  if (loading) return <Spinner />;

  const { high = [], ideal = [], low = [] } = data?.getUsersPtoSpend || {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
        marginX: '0.5rem',
        width: { xs: '20rem', sm: '25rem', md: '35rem' },
      }}
    >
      <Header sx={{ width: '91%', margin: '0 auto' }}>PTO Spend</Header>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: caxyGreen,
          },
          '& .Mui-selected': {
            color: 'black',
          },
          marginTop: '.5rem',
        }}
      >
        <StyledTab label='High' />
        <StyledTab label='Ideal' />
        <StyledTab label='Low' />
      </Tabs>
      <CustomTabPanel value={activeTab} index={0}>
        <UserTable data={high} type={'High'} />
      </CustomTabPanel>

      <CustomTabPanel value={activeTab} index={1}>
        <UserTable data={ideal} type={'Ideal'} />
      </CustomTabPanel>

      <CustomTabPanel value={activeTab} index={2}>
        <UserTable data={low} type={'Low'} />
      </CustomTabPanel>
    </Box>
  );
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default PtoSpend;
