import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers {
    users {
      wfhDays
      username
      startDate
      role
      ptoRate
      ptoHours
      id
      flexDays
      emailSubscriptions
      email
      auth0Id
      displayName
    }
  }
`;
