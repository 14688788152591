export type SortType = 'asc' | 'desc';

export const sortData = <T>(data: T[], column: keyof T, sortType: SortType) => {
  let sortedData = [...data];

  sortedData = sortedData.sort((a: T, b: T) => {
    const aValue = a[column];
    const bValue = b[column];

    if (aValue === null || bValue === null) {
      // If column is an invalid property, return the original array unchanged
      return 0;
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortType === 'asc' ? aValue - bValue : bValue - aValue;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortType === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    } else {
      // If there's a type error, return the original array unchanged
      return 0;
    }
  });

  return sortedData;
};
