import { Box, TextField, InputLabel } from '@mui/material';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../../../graphql/users/mutations/updateUser';
import ReusableButton from '../../../components/buttons/ReusableButton';
import { useState, useEffect } from 'react';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';

const EditProfile = () => {
  const { currentUserData } = useCurrentUser();

  const [displayName, setDisplayName] = useState<string>('');
  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (currentUserData?.userByEmail?.displayName) {
      setDisplayName(currentUserData.userByEmail.displayName);
    }
  }, [currentUserData]);

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);
  };

  const handleSubmit = async () => {
    updateUser({
      variables: {
        input: {
          id: currentUserData?.userByEmail?.id,
          displayName,
        },
      },
    });
  };

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        margin: '0 auto',
        width: '85vw',
        maxWidth: 600,
      }}
    >
      <InputLabel sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>Email</InputLabel>
      <TextField
        fullWidth
        variant='outlined'
        value={currentUserData?.userByEmail?.email ?? ''}
        InputProps={{
          readOnly: true,
        }}
        sx={{ mb: 2, backgroundColor: 'white' }}
      />

      <InputLabel sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>Display Name</InputLabel>
      <TextField
        fullWidth
        variant='outlined'
        value={displayName}
        onChange={handleDisplayNameChange}
        sx={{ mb: 2, backgroundColor: 'white' }}
      />

      <ReusableButton variant='outlined' href='/profile' onClick={handleSubmit}>
        Update
      </ReusableButton>
    </Box>
  );
};

export default EditProfile;
