import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';

interface TypeDropdownMenuProps {
  selectedValue: string;
  handleSelectChange: (e: SelectChangeEvent<string>) => void;
  disabled?: boolean;
}

const TypeDropdownMenu: FC<TypeDropdownMenuProps> = ({
  selectedValue,
  handleSelectChange,
  disabled,
}) => {
  return (
    <>
      <InputLabel
        sx={{ fontWeight: 'bold', color: 'black', mb: selectedValue !== 'pto' ? '-1rem' : '' }}
      >
        Type
      </InputLabel>
      <FormControl fullWidth>
        <Select value={selectedValue} onChange={handleSelectChange} disabled={disabled}>
          <MenuItem value='pto'>Time Off</MenuItem>
          <MenuItem value='exception'>Exception Time</MenuItem>
          <MenuItem value='wfh'>Work From Home</MenuItem>
          <MenuItem value='flex'>Flex</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default TypeDropdownMenu;
