import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useState, createContext, useContext, useEffect, useCallback } from 'react';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_AUTH_ID } from '../graphql/users/mutations/addUserAuth';
import { GET_USER_BY_EMAIL } from '../graphql/users/queries/getUserByEmail';
import { Role } from '../enums/role';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export interface User {
  userByEmail: {
    id: string;
    username: string;
    email: string;
    role: Role;
    startDate: Date;
    ptoRate: number;
    flexDays: number;
    wfhDays: number;
    exceptionDays: number;
    emailSubscriptions: string;
    ptoHours: number;
    displayName: string;
    auth0Id: string | null;
  };
}

export interface CurrentUserContextType {
  currentUserData: User | null | undefined;
  setCurrentUserData: React.Dispatch<React.SetStateAction<User | null | undefined>>;
  isAdmin: boolean;
  authToken: string | null;
  setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
  deny: boolean;
  setDeny: React.Dispatch<React.SetStateAction<boolean>>;
  denyEvent: boolean;
  setDenyEvent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUserData: null,
  setCurrentUserData: () => {},
  isAdmin: false,
  authToken: null,
  setAuthToken: () => null,
  deny: false,
  setDeny: () => {},
  denyEvent: false,
  setDenyEvent: () => {},
});

const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [currentUserData, setCurrentUserData] = useState<User | null | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(null);

  const [deny, setDeny] = useState<boolean>(false);
  const [denyEvent, setDenyEvent] = useState<boolean>(false);

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useQuery(GET_USER_BY_EMAIL, {
    variables: {
      email: user?.email,
    },
    skip: !user?.email,
    onCompleted: async (data) => {
      // If user doesn't have their auth0Id yet, once they log in for the first time, it should set their auth0Id to the id from auth0 object
      if (!data.userByEmail.auth0Id) {
        await addUserAuth({
          variables: {
            input: {
              email: user?.email,
              auth0Id: user?.sub,
            },
          },
        });
      }

      if (data.userByEmail?.role === Role.ADMIN) {
        setIsAdmin(true);
      }
    },
    onError: async (error) => {
      setCurrentUserData(null);
      // for any network errors, navigate to '/500' error page
      if (error.networkError) {
        navigate('/500');
        return;
      }

      // for any graphQL errors, with login, etc, respond with the following
      if (error.graphQLErrors) {
        toast.dismiss();
        toast.error(
          'We could not find an account associated with the provided login details. \n\n Please check your credentials and try logging in again. \n\nIf you believe this is an error, please contact Operations.',
          {
            duration: 7000,
          }
        );
      }
    },
  });
  const getAccessToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    setAuthToken(token);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user) {
      getAccessToken();
    }
  }, [getAccessToken, getAccessTokenSilently, user]);

  useEffect(() => {
    if (data) {
      setCurrentUserData(data);
    }
  }, [data]);

  const [addUserAuth] = useMutation(ADD_AUTH_ID);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUserData,
        setCurrentUserData,
        isAdmin,
        authToken,
        setAuthToken,
        deny,
        setDeny,
        denyEvent,
        setDenyEvent,
      }}
    >
      {' '}
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
