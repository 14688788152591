import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { RequestStatus } from '../../enums/RequestStatus';

interface Props {
  initialStatus: RequestStatus;
  handleSelectStatus: (status: RequestStatus) => void;
  disabled?: boolean;
}

const RequestStatusDropdown = ({ initialStatus, handleSelectStatus, disabled }: Props) => {
  const [selectedStatus, setSelectedStatus] = useState<RequestStatus | null>(initialStatus);

  const handleChange = (event: React.SyntheticEvent, value: RequestStatus | null) => {
    setSelectedStatus(value);
    if (value) {
      handleSelectStatus(value);
    }
  };

  return (
    <Autocomplete
      value={selectedStatus}
      options={Object.values(RequestStatus)}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleChange}
      getOptionLabel={(option: RequestStatus) => option}
      disabled={disabled}
    />
  );
};

export default RequestStatusDropdown;
