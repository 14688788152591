import { Box, Divider } from '@mui/material';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';
import { useLocation } from 'react-router-dom';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
`;

const EmployeeHourContainer = styled(Box)`
  display: flex;
  margin: 0 auto;
  margin-top: 0.4rem;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.025rem;
  width: 26rem;

  @media (max-width: 27rem) {
    width: 19rem;
  }

  @media (max-width: 22rem) {
    width: 18rem;
  }
`;
const EmployeeHourType = styled(Typography)`
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 1rem;
  width: 10rem;

  font-weight: bold;
`;

const EmployeeHour = styled(Typography)`
  display: flex;
  text-align: left;
  font-size: 1rem;
  width: 10rem;
`;

const ResponsiveDivider = styled(Divider)`
  width: 26rem;
  max-width: 90%;
  margin: 0 auto;
  border-color: black;
  border-width: 0.05rem;

  @media (max-width: 27rem) {
    width: 19rem;
  }

  @media (max-width: 22rem) {
    width: 18rem;
  }
`;

const UserStats = () => {
  const { currentUserData } = useCurrentUser();
  const user = currentUserData?.userByEmail;
  const isDataAvailable =
    typeof user?.ptoHours === 'number' &&
    typeof user?.flexDays === 'number' &&
    typeof user?.wfhDays === 'number' &&
    typeof user?.exceptionDays === 'number';
  const location = useLocation();
  const path = location.pathname;

  return (
    <Container>
      <EmployeeHourContainer>
        {path === '/requests/pending' ? (
          ''
        ) : (
          <>
            <EmployeeHourType> PTO Hours Remaining </EmployeeHourType>
            <EmployeeHourType> WFH Days Remaining </EmployeeHourType>
            <EmployeeHourType> Flex Days Remaining </EmployeeHourType>
            <EmployeeHourType> Exception Days Remaining </EmployeeHourType>
          </>
        )}
      </EmployeeHourContainer>
      <ResponsiveDivider
        orientation='horizontal'
        variant='middle'
        color='black'
        sx={{ margin: '0 auto' }}
      />
      {isDataAvailable && (
        <EmployeeHourContainer>
          {path === '/requests/pending' ? (
            ''
          ) : (
            <>
              <EmployeeHour>{user?.ptoHours}</EmployeeHour>
              <EmployeeHour>{user?.wfhDays}</EmployeeHour>
              <EmployeeHour>{user?.flexDays}</EmployeeHour>
              <EmployeeHour>{user?.exceptionDays}</EmployeeHour>
            </>
          )}
        </EmployeeHourContainer>
      )}
    </Container>
  );
};

export default UserStats;
